@import url('https://fonts.googleapis.com/css?family=Roboto|Lato|Open+Sans|Montserrat|Oswald|Source+Sans+Pro|Slabo+27px|Raleway|Concert+One|Prompt');
.App-logo-header {
  align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ffffff;
  /*#61dafb;*/
  font-family: Open Sans serif;
  font-style: normal;
  font-size: 3px;
  a:visited {
    font-weight: bold;
  }
}

.App-addtag {
  height: 30px;
  pointer-events: none;
  align: 'left';
}

.App-linebreak {
  padding-bottom: 30px;
}

.MuiDrawer-paper {
  padding: 0 !important;
}

.paragraphHeader {
  font-size: 12pt;
  font-weight: bold;
  font-family: Open Sans serif;
}

.card {
  margin-bottom: 20px;
}

.floatButton {
  color: white;
  padding: 5px 5px;
  margin: 4px 0;
  border: none;
  cursor: pointer;
  font-size: 14pt;
  font-weight: bold;
  font-family: Open Sans serif;
  float: left;
}

@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi-Variable'),
    url(./fonts/Satoshi-Variable.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'SatoshiVariable';
  font-width: 400;
  src: local('Satoshi'),
    url(./fonts/satoshi/Satoshi-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'SatoshiVariableBold';
  font-width: 700;
  src: local('Satoshi'),
    url(./fonts/satoshi/Satoshi-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Heebo-Black';
  font-width: 700;
  src: local('Heebo'), url(./fonts/heebo/Heebo-Black.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Heebo-Bold';
  font-width: 700;
  src: local('Heebo'), url(./fonts/heebo/Heebo-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Heebo-ExtraBold';
  font-width: 700;
  src: local('Heebo'), url(./fonts/heebo/Heebo-ExtraBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Heebo-Light';
  font-width: 700;
  src: local('Heebo'), url(./fonts/heebo/Heebo-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Heebo-Medium';
  font-width: 700;
  src: local('Heebo'), url(./fonts/heebo/Heebo-Medium.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Heebo-Regular';
  font-width: 700;
  src: local('Heebo'), url(./fonts/heebo/Heebo-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Heebo-Thin';
  font-width: 700;
  src: local('Heebo'), url(./fonts/heebo/Heebo-Thin.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Open Sans';
  src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
}

@font-face {
  font-family: 'Lato';
  src: url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
}

@font-face {
  font-family: 'Merriweather';
  src: url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
}

@font-face {
  font-family: 'Poppins';
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
}

.awssld__bullets .awssld__bullets--active {
  background-color: #0f152a !important;
}

.awssld__bullets button {
  background-color: #d9e2ff !important;
  width: 10px !important;
  height: 10px !important;
}

.awssld__content {
  align-items: flex-start !important;
  overflow-y: scroll !important;
  padding-left: 5px !important;
  /* padding-top: 10px !important; */
  /* padding: 10px !important; */
}

.awssld__content p {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.awssld__content span {
  /* padding-left: 14px !important;
  padding-right: 14px !important; */
  /* display: inline-block; */
}

.awssld__bullets {
  bottom: auto !important;
  z-index: 1000 !important;
  top: -30px !important;
}

.awssld__bullets button {
  background-color: #fff !important;
  border: 2px solid #c7c7c7 !important;
  width: 12px !important;
  height: 1px !important;
  padding-right: 4px !important;
  border-radius: 0 !important;
}

.awssld__bullets .awssld__bullets--active {
  background-color: transparent !important;
  border-color: #0095f6 !important;
}

.awssld__bullets .awssld__bullets--active:after {
  content: '';
  background-color: #0095f6;
  border-radius: 50%;
  width: 6px;
  height: 2px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.awssld__controls {
  visibility: visible !important;
}

.rdw-option-wrapper {
  min-width: 5px !important;
  height: 10px !important;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-container iframe {
  max-width: 100%;
}

.content-container video {
  max-width: 100% !important;
  height: auto !important;
}

.content-container img {
  max-width: 100% !important;
  /* height: auto !important; */
}

.awssld__content > div:first-child {
  height: 100%;
}

/* .content-container:not(:first-child(img)):not(:first-child(video)) {
  padding-left: 14px !important;
  padding-right: 14px;
  display: inline-block;
} */

/* .content-container > :first-child:not(.content-container):not(img) {
  padding-left: 14px;
  padding-right: 14px;
} */
/* .content-container:first-child:not(img, video, .content-container) {
  padding-left: 14px;
  padding-right: 14px;
  word-wrap: break-word;
  display: inline-block;
} */
.content-container
  > :where(
    :not(
        img,
        video,
        [data-bgcolor-wrapper],
        .content-container,
        .color-container,
        .responsive-iframe,
        ol
      )
  ) {
  padding-left: 14px;
  padding-right: 14px;
}

/* .content-container
  > :where(:not(img, video, .content-container, .color-container)) {
  padding-left: 14px;
  padding-right: 14px;
  display: inline-block;
}

.color-container
  > :where(:not(img, video, .content-container, .color-container)) {
  padding-left: 14px;
  padding-right: 14px;
  display: inline-block;
} */

.content-container table {
  padding: 14px !important;
  display: inline-block;
}

.content-container a {
  box-sizing: border-box !important;
}

/*div.DraftEditor-root {*/
/*    box-sizing: border-box;*/
/*    border: 1px solid #ddd;*/
/*    cursor: text;*/
/*    padding: 16px;*/
/*    border-radius: 2px;*/
/*    margin-bottom: 2em;*/
/*    box-shadow: inset 0px 1px 8px -3px #ABABAB;*/
/*    background: #fefefe;*/
/*}*/

.headlineButtonWrapper {
  display: inline-block;
}

.responsive-iframe {
  /* position: relative; */
  height: 90vh;
}

.responsive-iframe iframe {
  border: 0;
  height: 88vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.responsive-canvas {
  width: 100%;
  border: 0;
}

.img-responsive {
  max-width: 100% !important;
  height: auto !important;
}

.headlineButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.MuiMobileStepper-root {
  background: transparent;
  z-index: 100;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

/* Template 2 - Modern */
.mce-content-body {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px !important;
  color: #555;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.mce-btn {
  background-color: #1e90ff;
  color: #fff;
  border: none;
  border-radius: 2px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* General editor styles */
.tox .tox-editor-container {
  border-radius: 8px; /* Rounded corners for the editor container */
  border: 1px solid #d1d5db; /* Light grey border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.tox .tox-toolbar,
.tox .tox-toolbar__primary {
  background-color: #f9fafb; /* Light background for the toolbar */
  border-bottom: 1px solid #d1d5db; /* Separator between toolbar and content */
}

.tox .tox-toolbar__group:not(:last-child) {
  border-right: none; /* Remove separators between toolbar buttons for a cleaner look */
}

.tox .tox-toolbar__group button {
  border-radius: 4px; /* Rounded buttons */
}

.tox .tox-tbtn {
  color: #374151; /* Dark grey color for icons */
}

.tox .tox-tbtn--enabled:hover,
.tox .tox-tbtn:focus {
  background-color: #e5e7eb; /* Light grey background on hover/focus */
  color: #111827; /* Slightly darker text/icon color for contrast */
}

/* Editor content area */
.tox .tox-edit-area {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Modern, clean font */
  line-height: 1.6; /* Improved readability with more line spacing */
}

/* Active and hover states for toolbar buttons */
.tox .tox-tbtn--enabled:hover,
.tox .tox-tbtn--enabled:focus,
.tox .tox-tbtn--enabled:active {
  background-color: #eff6ff; /* Very light blue for active/hover states */
  color: #1d4ed8; /* Matching darker blue for text/icons */
}

/* Adjusting the color picker to match the theme */
.tox .tox-swatches {
  border-radius: 4px;
}

.tox .tox-form__group--stretched .tox-textfield,
.tox .tox-form__group--stretched .tox-selectfield,
.tox .tox-form__group--stretched .tox-textarea {
  border-radius: 4px; /* Consistent rounded corners for form elements */
}

.link-preview {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  max-width: 600px;
}
.link-preview-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
}
.link-preview-content {
  padding: 15px;
}
.link-preview-title {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: bold;
}
.link-preview-description {
  margin: 0 0 15px;
  font-size: 14px;
  color: #555;
}
.link-preview-url {
  display: inline-block;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
}
.link-preview-url:hover {
  background-color: #0056b3;
}
