.showView {
  font-family: sans-serif;
    /*background-color: #282c34;*/
    padding: 50px 10px;
    width: 95%;
    border-radius: 5%;
    margin: auto;
    vertical-align: top;
    @media screen and (min-width: 767px) {
      width: 35%;
    }
}

.floatButton {
  color: white;
  padding: 5px 5px;
  margin: 4px 0;
  border: none;
  cursor: pointer;
  font-size: 14pt;
  font-weight: bold;
  font-family: Open Sans serif;
  float: left;
}

.pageHeader {
  font-size: 14pt;
  font-weight: normal;
  font-family: Open Sans serif;
}

.paragraphHeader {
  font-size: 12pt;
  font-weight: bold;
  font-family: Open Sans serif;
  margin: 15px;
  padding: 0px 20px;
  vertical-align: top;
}

.paragraphText {
 font-size: 12pt;
 font-weight: normal;
 font-family: Open Sans serif;
 margin: 15px;
 padding: 0px 20px;
 vertical-align: top;
}

.linebreak {
    padding-bottom: 25px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
