.logButton {
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 14pt;
  font-weight: bold;
}

.logButton:hover {
  opacity: 0.8;
}

.labelText {
 width: 100%;
 padding: 2px 5px;
/* margin: 0px 0;*/
 display: flex;
 font-size: 14pt;
 font-weight: bold;
 color: #FFFFFF;
 /*border: 1px solid #ccc;*/
 box-sizing: border-box;
 align: left;
}

.loginView{
    font-family: sans-serif;
    background-color: #282c34;
    color: #fff;
    font-size: 1.4em;
    padding: 50px;
    width: 100%;
    border-radius: 0%;
    margin: auto;
    @media screen and (min-width: 767px) {
      width: 35%;
    }
}

.loginCheckbox {
  font-size: 14pt;
  padding: 0px;
  color: #FFFFFF;
  font-weight: bold;
  align: left;
}

.loginHref {
  font-style: normal;
  font-size: 14px;
  font-weight: bold;
  float: center;
}

.loginView form {
  margin: 0px 32px;
}
