.showView {
  text-align: left;
  vertical-align: top;
  font-size: 14pt;
  font-weight: normal;
  font-family: Open Sans serif;
  margin: 45px;
  padding: 20px 20px;
  align: center;


}

.floatButton {
  color: white;
  padding: 5px 5px;
  margin: 4px 0;
  border: none;
  cursor: pointer;
  font-size: 8pt;
  font-weight: bold;
  font-family: Open Sans serif;
  float: left;
}

.pageHeader {
  font-size: 14pt;
  font-weight: normal;
  font-family: Open Sans serif;
}

.paragraphHeader {
  font-size: 12pt;
  font-weight: bold;
  font-family: Open Sans serif;
}

.text {
 /*padding: 2px 5px;*/
/* margin: 0px 0;*/
 /*display: flex;*/
 font-size: 12pt;
 font-weight: normal;
 color: black;
 font-family: Open Sans serif;
 align: left;
 width: 100%;
}

.linebreak {
    padding-bottom: 25px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
